import React from "react";
import blog3 from "../../Assets/images/blog13.jpg";
import "./Bekmirza.scss";

function Blog3() {
  return (
    <div className='blog_box'>
      <img src={blog3} className='blog' alt='blog__--img' />
      <div className='text'>
        <h1 className='header_blog'>
          BASCO ERP платформасини компаниянгизга жорий қилганингизда ҳеч нарса
          аввалгидек бўлмайди.
        </h1>
        <p className='blog_text'>
          <span className='text_blog1'>
            (Самарадорлик ошади, харажатлар камаяди ва компаниянгиз
            рақобатчиларингизни ҳайрон қолдиришни бошлайди)
          </span>
          <br />
          <span className='text_blog1'>
            Булар, шунчаки, сизга нимадир сотишга уриниш учун кўпиртириб
            айтилаётган гаплар эмас.
          </span>
          <span className='text_blog1'>
            Aксинча, бу мақолани ўқиш учун сарфлаган вақтингиз сиз учун энг
            манфаатлиси бўлишига ишонамиз.
          </span>
          <span className='text_blog1'>Тахмин қилишим мумкин…</span>
          <span className='text_blog1'>
            Сиз бизнесни автоматлаштириш ҳақида кўп ешитгансиз.
          </span>
          <span className='text_blog1'>
            Лекин унинг аслида, сизнинг бизнесингиз учун реал рақамларда қандай
            манфаат олиб келиши ҳақида аниқ тасаввурга эга бўлмаслигингиз
            мумкин.
          </span>
          <span className='text_blog1'>Бу ҳақида гапиришдан олдин…</span>
          <span className='text_blog1'>
            Ўзи бизнесингиз жараёнларини автоматлаштириш керак бўлган вақтни
            қайердан биласиз?
          </span>
          <b className='text_blog1'>
            Aгар сизда қуйидаги муаммолар учраётган бўлса…
          </b>
          <span className='text_111'>
            ●Доимий равишда бажариладиган майда ва мураккаб ишларнинг қўлда
            бажарилиши ва бу орқали қимматли вақтингизнинг аксарияти ҳавога
            совурилаётгани;
          </span>
          <span className='text_111'>
            ● Омбордаги маҳсулотларингиз ҳаракати тўғрисидаги аниқ ҳисоботни
            реал вақтда кўра олмаслик;
          </span>
          <span className='text_111'>
            ● Таклиф ва талаб мутаносиблигини била олмаслик;
          </span>
          <span className='text_111'>
            ● Барча ҳисоб-китоблар ва натижадаги молиявий ҳолатингизни аниқ
            билмаслик;
          </span>
          <span className='text_111'>● Ва Ҳаказо</span>
          <span className='text_blog1'>
            Сиз, аллақачон бизнесингиз жараёнларини автоматлаштиришингиз керак.
          </span>
          <span className='text_blog1'>
            Бунинг ўзи ҳам барча муаммоларингизни ҳал қилмаслиги мумкин.
          </span>
          <span className='text_blog1'>
            Aйнан, шуларнинг барчаси учун бизнесингизга ERP платформаси керак.
          </span>
          <b className='text_blog1'>Хўш, ўзи ERP платформаси нима?</b>
          <span className='text_blog1'>
            ERP (Enterprise Resource Planning) дастурлари бугунги кунда дунёнинг
            етакчи ишлаб чиқариш, савдо ва хизмат кўрсатиш компаниялари
            томонидан фойдаланиладиган тизим ҳисобланади.
          </span>
          <span className='text_blog1'>
            Бизнесингиз қисқа муддатда ривожланиб, янги бозорларга жадаллик
            билан кириб бориши учун бундан яхши йечимни топа олмайсиз!
          </span>
          <span className='text_blog1'>
            Ўзбекистон бозорида жадаллик билан ривожланиб бораётган компаниялар
            бизнесларидаги барча жараёнлар автоматизацияси ва назорати учун ERP
            платформаларидан фойдаланишади.
          </span>
          <b className='text_blog1'>
            Бугун сизга ўнлаб компаниялар фойдаланиб келаётган BASCO ERP
            платформасини таништираман.
          </b>
          <span className='text_blog1'>Фақат, бир нарсани билинг…</span>
          <span className='text_blog1'>
            <b> Бу мақола BASCO ERP платформаси ҳақида эмас</b>. Aксинча, бу -
            сиз ҳақингизда.
          </span>
          <span className='text_blog1'>
            Ва ушбу платформа сизнинг бизнеиснгизга қанчалик ёрдам бера олишини
            аниқлаш ҳақида бўлади.
          </span>
          <b className='text_blog1'>BASCO ERP платформаси</b>
          <span className='text_blog1'>
            BASCO ERP платформаси компаниянгиздаги муҳим фундаментал жараёнларни
            самарали бошқариш ва автоматлаштириш имкониятини беради. .
          </span>
          <span className='text_blog1'>
            Бу билан эса ўша заҳотиёқ бизнесингиздаги операцион ва молиявий
            ҳолатни яққол кўриш имкониятига эга бўласиз.
          </span>
          <span className='text_blog1'>
            Ҳолатни аниқ кўра олиш эса тўғри ва тезкор қарорларни қабул
            қилишингизга ёрдам беради.
          </span>
          <span className='text_blog1'>
            Бу орқали ўз-ўзидан молиявий ҳолатингизни ва самарадорлигингизни бир
            неча бараварга оширишингиз мумкин бўлади.
          </span>
          <span className='text_blog1'>
            Ягона тизим орқали бизнесингиздаги бутун бошли мижозлар билан
            муносабатлар, савдо жараёнлари, ҳодимлар билан боғлиқ барча
            маълумотлар, компаниянинг барча турдаги ҳисоб-китобларидан тортиб…
          </span>
          <span className='text_blog1'>
            Складдаги тайёр маҳсулотлар ҳаракатигача бўлган аниқ маълумотларни
            назорат қилишингиз ва бошқаришингиз мумкин бўлади.
          </span>
          <span className='text_blog1'>
            Маълумотларнинг аниқлиги сабаб, сиз тез ва тўғри қарорларни қабул
            қилиш имкониятига эга бўласиз.
          </span>
          <span className='text_blog1'>
            Вақтингизни бизнесингизга тўғридан-тўғри фойда олиб келмайдиган
            ишларга сарфлашдан тўхтаб…
          </span>
          <span className='text_blog1'>
            Банк ҳисоб-рақамингизда, ҳақиқатда, ижобий ўзгариш қиладиган ва
            компаниянгизнинг бозордаги улушини оширадиган муҳим ишларга диққат
            қила бошлайсиз.
          </span>
          <span className='text_blog1'>
            <b> BASCO ERP платформаси бўлимлари</b> <br /> BASCO ERP платформаси
            қуйидаги 13 та бўлимдан иборат:
          </span>
          <span className='text_blog1'>
            <span>
              1.Раҳбар бўлими; <br /> 2.CRM бўлими; <br /> 3.Савдо бўлими;{" "}
              <br /> 4.HR бўлими; <br /> 5.Маош бўлими; <br /> 6.Касса бўлими;{" "}
              <br /> 7.Назорат бўлими; <br /> 8.Склад бўлими; <br /> 9.Таъминот
              бўлими; <br /> 10.Турли шахслар бўлими; <br /> 11.Aсосий воситалар
              бўлими; <br /> 12.Ишлаб чиқариш бўлими; <br /> 13.Бухгалтерия
              бўлими;
            </span>
          </span>
          <span className='text_blog1'>Юқорида кўриб турганингиздек…</span>
          <span className='text_blog1'>
            Платформадаги бўлимлар бизнесингизнинг барча жараёнларини тўлиқ
            назорат қилиш имконини беради.
          </span>
          <span className='text_blog1'>
            Биргина раҳбар бўлимининг имкониятларини кўриб чиқадиган бўлсак:
          </span>
          <span className='text_blog1'>
            <span>
              ● Раҳбар компаниянинг умумий балансини тўлақонли кўра олади;{" "}
              <br />
              ● Компания ҳисоботлари тартибга тушади. Дастурнинг ҳар бир бўлими
              ўзаро боғлиқ ҳолда ишлагани сабабли барча фойдаланувчилар
              маълумотларни ўз вақтида киритиб боришини талаб қилади. Натижада,
              ҳисоботлар тўғри ва аниқ юритилади. <br /> ● Ҳар бир бўлимнинг
              раҳбар билан мулоқоти тушунарли ва осон бўлади. <br /> ● Компания
              раҳбарининг ҳисоботларни кўриши осонлашади. Компания ривожланиши
              тезлашади.
            </span>
          </span>
          <span className='text_blog1'>
            Платформадаги бўлимлар бизнесингизнинг барча жараёнларини тўлиқ
            назорат қилиш имконини беради.
          </span>

          <span className='text_blog1'>CRM бўлимидаги имкониятлар эса…</span>
          <span className='text_blog1'>
            Сотув бўлими ҳодимларингизга ёқиши аниқ. Чунки, у ердаги имкониятлар
            сотув операторларингизнинг самарадорлигини оширишга қаратилган.
          </span>
          <span className='text_blog1'>
            Қолган бўлимларнинг тўлиқ имкониятлари билан танишиб чиқишингиз
            учун…
          </span>
          <span className='text_blog1'>
            Шунчаки, бизнесни BASCO автоматлаштириш мутахассислари билан БЕПУЛ
            консултация банд қилишингиз мумкин.
          </span>
          <span className='text_blog1'>
            Бу орқали ишлаб чиқариш бизнесингизни автоматлаштириш сизга
            юқоридаги имкониятларни қай даражада бериши ҳақида аниқ ва батафсил
            маълумотга эга бўлишингиз мумкин.
          </span>
          <span className='text_blog1'>
            П.С. Консултация сўнгида сиз биздан сотиб олишга мажбур бўлмайсиз.
          </span>
          <span className='text_blog1'>
            Лекин, бир нарсани ишонч билан айта оламанки, консултацияда
            сарфлаган вақтингиз сиз учун энг яхши ва тўғри сарфланган вақтлардан
            бўлади.
          </span>
        </p>
        <a
          href='https://1usbf5hvh56.typeform.com/to/LZEFBbhu#hidden1=xxxxx'
          className='text_link'>
          Телефонда суҳбат белгилаш
        </a>
      </div>
    </div>
  );
}

export default Blog3;
