import React from "react";
import blog2 from "../../Assets/images/blog12.jpg";
import "./Bekmirza.scss";

function Blog2() {
  return (
    <div className='blog_box'>
      <img src={blog2} className='blog' alt='blog__--img' />
      <div className='text'>
        <h1 className='header_blog'>
          Сиз энг тез ривожланиб бораётган ишлаб чиқариш компанияларнинг сирини
          фош қилдингиз! Бу - ERP платформаси.
        </h1>
        <p className='blog_text'>
          <span className='text_blog1'>
            (Бу тизимни бизнесингизда жорий қилганингизда… Жараёнларда янги
            “портлаш”га гувоҳ бўласиз. Рост.)
          </span>
          <br />
          <span className='text_blog1'>Тасаввур қилинг…</span>
          <span className='text_blog1'>
            Компаниянгиз каттагина жамоага ва бир нечта бўлимларга эга бўлсада,
            сиз ундаги жараёнларни ягона тизим орқали, осонгина, назорат қила
            оласиз.
          </span>
          <span className='text_blog1'>
            Бизнесингиздаги барча мижозлар билан муносабатлар, савдо жараёнлари,
            ҳодимлар билан боғлиқ ҳамма маълумотлар, компаниянинг барча турдаги
            ҳисоб-китобларидан тортиб…
          </span>
          <span className='text_blog1'>
            Складдаги тайёр маҳсулотлар ҳаракатигача бўлган аниқ маълумотларни
            назорат қилишингиз ва бошқаришингиз мумкин…
          </span>
          <span className='text_blog1'>
            Маълумотларнинг аниқлиги сабаб сиз тез ва тўғри қарорларни қабул
            қилиш имкониятига эгасиз.
          </span>
          <span className='text_blog1'>
            Вақтингизни бизнесингизга тўғридан-тўғри фойда олиб келмайдиган
            ишларга сарфлашдан тўхтаб…
          </span>
          <span className='text_blog1'>
            Банк ҳисоб-рақамингизда, ҳақиқатда ижобий ўзгариш қиладиган ва
            компаниянгизнинг бозордаги улушини оширадиган муҳим ишларга диққат
            қила бошладингиз.
          </span>
          <b className='text_blog1'>Енди тўхтанг…</b>
          <span className='text_blog1'>
            Буларнинг барчасини сиз ҳозир тасаввур қилдингиз. Aйнан, шу
            дақиқаларда тасаввурларингизни реалликка чиқариш мумкинлигига гувоҳ
            бўласиз.
          </span>
          <span className='text_blog1'>Тайёр бўлсангиз, бошладик.</span>
          <span className='text_blog1'>
            Сизда шу пайтгача қуйидаги муаммолар бўлган бўлиши мумкин:
          </span>
          <span className='text_111'>
            ●Доимий равишда бажариладиган майда ва мураккаб ишларнинг қўлда
            бажарилиши;
          </span>
          <span className='text_111'>
            ●Омбордаги маҳсулотларингиз ҳаракати тўғрисидаги аниқ ҳисоботни реал
            вақтда кўра олмаслик;
          </span>
          <span className='text_111'>
            ●Таклиф ва талаб мутаносиблигини била олмаслик;
          </span>
          <span className='text_111'>
            ●Барча ҳисоб-китоблар ва натижадаги молиявий ҳолатингизни аниқ
            билмаслик;
          </span>
          <span className='text_111'>●Ва ҳоказо.</span>
          <span className='text_blog1'>
            Сиз бу муаммоларингизни яхши билишингиз ва булардан азият чекиб
            келаётган бўлишингиз мумкин.
          </span>
          <span className='text_blog1'>
            Лекин, буларни бартараф қилиш учун сизда аниқ ечим бўлмаса…
          </span>
          <b className='text_blog1'>Сизга янгилигим бор!</b>
          <span className='text_blog1'>
            Ва бу сиз ўқишингиз мумкин бўлган энг муҳим хабар ҳисобланади.
          </span>
          <span className='text_blog1'>
            Бу каби муаммоларингизнинг йечими оддий.
          </span>
          <span className='text_blog1'>
            Ва бу дунёдаги энг тез ривожланаётган ишлаб чиқариш компаниялари
            томонидан фойдаланиладиган ERP платформалари ёрдамида ҳал қилинади.
          </span>
          <span className='text_blog1'>
            ERP платформалари юқоридаги муаммоларингизни ҳал қилиб,
            компаниянгиздаги маълумотларнинг аниқлигини ошириб, сизни тўғри
            қарорларни қабул қилишингизга имкон берар экан…
          </span>
          <b className='text_blog1'>Хўш, ўзи ERP платформаси нима?</b>
          <span className='text_blog1'>
            ERP (Enterprise Resource Planning)дастурлари бугунги кунда дунёнинг
            етакчи ишлаб чиқариш, савдо ва хизмат кўрсатиш компаниялари
            томонидан фойдаланиладиган тизим ҳисобланади. Бизнесингиз қисқа
            муддатда ривожланиб, янги бозорларга жадаллик билан кириб бориши
            учун бундан яхши ечимни топа олмайсиз!
          </span>
          <span className='text_blog1'>
            Бизнесингизни автоматлаштиришнинг ушбу тизими орқали:
          </span>
          <span className='text_blog1'>
            <b> Зарурий жараёнлар автоматлашади</b> <br /> ERP дастурлари сиз
            доимий равишда бажариб юрган мураккаб жараёнларни содда, осон ва
            аниқ стратегик бошқаришга ёрдам беради.
          </span>
          <span className='text_blog1'>
            <b> Харажатларни камайтиради</b> <br /> Бунга ўзингиз ҳам ишонмаган
            бўлардингиз. Aвтоматлаштириш орқали операцион харажатларингиз
            камаяди. Камроқ ишчи кучи ва ресурс билан кўпроқ вазифаларни амалга
            оширасиз.
          </span>
          <span className='text_blog1'>
            <b> Самарадорликни оширади</b> <br />
            Инсон қўли билан бажариладиган доимий вазифалар йиғилиб бориб жуда
            ҳам кўп вақтингизни олади. Aвтоматлаштириш орқали маълумот
            алмашинуви тез амалга оширилади ва ҳодимларингиз самарадорлиги
            ошади.
          </span>
          <span className='text_blog1'>
            Буларнинг қуруқ гап эмаслигига ва энг муҳими бундай платформани сиз
            ҳам ўз бизнесингизда жорий қилишингиз мумкинлигига ишонч ҳосил
            қилишингиз керак.
          </span>
          <span className='text_blog1'>
            Бунинг учун сиз, шунчаки бизнесни автоматлаштириш мутахассислари
            билан БЕПУЛ консултация банд қилишингиз мумкин.
          </span>
          <span className='text_blog1'>
            Бу орқали ишлаб чиқариш бизнесингизни автоматлаштириш сизга
            юқоридаги имкониятларни қай даражада бериши ҳақида аниқ ва батафсил
            маълумотга эга бўлишингиз мумкин.
          </span>
          <span className='text_blog1'>
            П.С. Консултация сўнгида сиз биздан сотиб олишга мажбур бўлмайсиз.
          </span>
          <span className='text_blog1'>
            Лекин, бир нарсани ишонч билан айта оламанки, консултацияда
            сарфлаган вақтингиз сиз учун энг яхши ва тўғри сарфланган вақтлардан
            бўлади.
          </span>
        </p>
        <a
          href='https://1usbf5hvh56.typeform.com/to/LZEFBbhu#hidden1=xxxxx'
          className='text_link'>
          Телефонда суҳбат белгилаш
        </a>
      </div>
    </div>
  );
}

export default Blog2;
