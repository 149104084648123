import React from "react";
import blog1 from "../../Assets/images/blog11.jpg";
import "./Bekmirza.scss";

function Blog1() {
  return (
    <div className='blog_box'>
      <img src={blog1} className='blog' alt='blog__--img' />
      <div className='text'>
        <h1 className='header_blog'>
          Бунга ишонмаган бўлардингиз! Биргина автоматлаштириш орқали
          бизнесингиз самарадорлигини 35% га ошириб, харажатларингизни 29% га
          қисқартириши мумкин екан.
        </h1>
        <p className='blog_text'>
          <span className='text_blog1'>
            (Яқинда бир тадқиқотга кўзим тушди. Ундаги маълумот мени ҳайратга
            солди…!)
          </span>
          <br />
          <span className='text_blog1'>
            Компаниялар осонгина автоматлаштирилиши мумкин бўлган топшириқ ва
            вазифаларга қимматли вақтларининг 23% қисмини{" "}
            <a href='https://www.salesforce.com/form/conf/2017-connected-small-business-report/?leadcreated=true&redirect=true&chapter=&DriverCampaignId=7010M000001ybOqQAI&player=&FormCampaignId=7010M000001ybOMQAY&videoId=&playlistId=&mcloudHandlingInstructions=&landing_page=%2Fform%2Fpdf%2F2017-connected-small-business-report'>
              ҳавога совуришар екан.
            </a>
          </span>
          <span className='text_blog1'>
            Бу рақам, бошида, сизга унчалик ҳам катта туюлмаслиги мумкин.{" "}
          </span>
          <span className='text_blog1'>
            Лекин, ҳавога совурилаётган вақтингизни тўғридан-тўғри банк ҳисоб
            рақамингизда ижобий ўзгариш қиладиган ишларга сарфласангизда
            бизнесингизда қанчалик ўзгариш қилиши мумкинлигини тасаввур
            қилганингизда…
          </span>
          <span className='text_blog1'>
            Вақтингизнинг бир фоизини ҳам майда ва мураккаб ишларга сарфлашни
            хоҳламаган бўлардингиз.
          </span>
          <span className='text_blog1'>
            Хўш, унда бизнесдаги доимий равишда бажариладиган майда ва мураккаб
            ишларни вақтингизни сарфламасдан туриб, қандай қилиб амалга
            оширасиз?
          </span>
          <span className='text_blog1'>
            Жавоб: <b>Aвтоматлаштириш.</b>
          </span>
          <span className='text_blog1'>
            Сизни ҳайратга соладиган рақамларни келтиришда давом етаман…
          </span>
          <span className='text_blog1'>
            Бизнесингиздаги доимий равишда бажариладиган майда ва мураккаб
            жараёнларни автоматлаштириш орқали самарадорлигингиз қисқа
            муддатларда 23% га ошиб, харажатларингиз 35% га қисқариши мумкин.
          </span>
          <span className='text_blog1'>
            Қўлда бажариладиган ишларга вақт сарфламаслик орқали самарадорлик
            ошишини тушундик. Қандай қилиб бу орқали харажатларим қисқариши
            мумкин?
          </span>
          <span className='text_blog1'>
            Aгар сиз шу саволни берган бўлсангиз, хурсандман. Сабаби, айнан шу
            саволнинг жавобида автоматлаштиришнинг энг муҳим вазифаси аҳамиятини
            очган бўламиз.
          </span>
          <span className='text_blog1'>
            Aгар сиз бизнесингиздаги жараёнларни автоматлаштирсангиз:
          </span>
          <span className='text_blog1'>
            ● Самарадорликка камроқ ҳодим билан ҳам эриша оласиз;
          </span>
          <span className='text_blog1'>
            ● Бизнесингиздаги операцион харажатларни қисқартирган бўласиз;
          </span>
          <span className='text_blog1'>
            Самарадорликнинг ошиши ва харажатларнинг қисқариши бизнес
            жараёнларини автоматлаштириш билан амалга ошириш мумкин бўлган
            дастлабки фойдалар, холос.
          </span>
          <span className='text_blog1'>
            Келинг, ундан ҳам қизиқ нарса ҳақида гаплашамиз.
          </span>
          <span className='text_blog1'>
            Хавотир олманг, бу мақолада баъзи бизнес тренерлар ўргатадиган
            қандай қилиб миллионер бўлиш мумкинлиги ҳақидаги “қуруқ” гаплар
            гапирилмайди.
          </span>
          <span className='text_blog1'>Энди мавзуга қайтсак…</span>
          <span className='text_blog1'>
            Харажатларингизни оширмасдан, самарадорлик ва даромадингизни қандай
            қилиб оширишингиз мумкин?
          </span>
          <span className='text_blog1'>
            Бу саволнинг жавоби бизнес жараёнларини автоматлаштириш берадиган
            кейинги катта фойдаси ҳисобланади.
          </span>
          <span className='text_blog1'>
            Гап бизнесингиздаги аниқ рақамларни вақтида кўриб туриш ва уларни
            назорат қилиш ҳақида кетмоқда.
          </span>
          <span className='text_blog1'>
            Aгар сиз бизнесингизнинг молиявий ҳолати, маҳсулотларингизга бўлган
            талаб даражаси, ҳодимларингизнинг самарадорлиги, сотувларингиз,
            фойда ва харажатларингиз ҳақидаги барча маълумотларни реал вақт
            ҳолатига кўра билиб турсангиз…
          </span>
          <span className='text_blog1'>
            Қарор қабул қилишингиз ҳозиргидан бошқача бўлиши аниқ.
          </span>
          <span className='text_blog1'>
            Бизнесни автоматлаштириш орқали орқали сиз бизнесингиздаги барча
            рақамларни реал вақт ҳолатига кўра аниқ кўра оласиз.
          </span>
          <span className='text_blog1'>
            … Ва бу сизга энг керакли вақтда тўғри қарорларни қабул қилиш
            имконини беради.
          </span>
          <b className='text_blog1'>Хулоса.</b>
          <span className='text_blog1'>
            Биргина мақоланинг ўзида сизга бизнес жараёнларини автоматлаштириш
            қандай қилиб бутун бошли бизнесингиз ҳолатини ўзгартириб юбориши
            мумкинлиги ҳақида ҳаммасини айтиш қийин.
          </span>
          <span className='text_blog1'>Лекин, бу сизга қизиқ бўлса…</span>
          <span className='text_blog1'>
            Шунчаки, бизнесни автоматлаштириш мутахассислари билан БЕПУЛ
            консултация банд қилишингиз мумкин.
          </span>
          <span className='text_blog1'>
            Бу орқали бизнесни автоматлаштириш сизга юқоридаги имкониятларни қай
            даражада бериши ҳақида аниқ ва батафсил маълумотга эга бўлишингиз
            мумкин.
          </span>
          <span className='text_blog1'>
            П.С. Консултация сўнгида сиз биздан сотиб олишга мажбур бўлмайсиз.
          </span>
          <span className='text_blog1'>
            Лекин, бир нарсани ишонч билан айта оламанки, консултацияда
            сарфлаган вақтингиз сиз учун энг яхши ва тўғри сарфланган вақтлардан
            бўлади.
          </span>
          <span className='text_blog1'>Лекин, бу сизга қизиқ бўлса…</span>
        </p>
        <a
          href='https://1usbf5hvh56.typeform.com/to/LZEFBbhu#hidden1=xxxxx'
          className='text_link'>
          Телефонда суҳбат белгилаш
        </a>
      </div>
    </div>
  );
}

export default Blog1;
